import React from "react";
import "./Footer.css";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import logo from "../images/nobglogo.png";

const Footer = () => {
  return (
    <footer className="bg-dark text-white py-4">
      <div className="container">
        <div className="row">
          {/* Logo Section */}
          <div className="col-md-3 text-center text-md-start mb-3 mb-md-0">
            <img src={logo} alt="Logo" className="footer-logo" />
          </div>

          {/* Our Product Section */}
          <div className="col-md-3 mb-3 mb-md-0">
            <h5 className="text-danger">Our Product</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/orderonline">Snacks </a>
              </li>
              <li>
                <a href="/orderonline">Curry</a>
              </li>
              <li>
                <a href="/orderonline">Biryani</a>
              </li>
              <li>
                <a href="/orderonline">Tandoori</a>
              </li>
              <li>
                <a href="/orderonline">Naan Roti</a>
              </li>
            </ul>
          </div>

          {/* Quick Links Section */}
          <div className="col-md-3 mb-3 mb-md-0">
            <h5 className="text-danger">Quick Links</h5>
            <ul className="list-unstyled">
              <li>
                <a href="/" className="text-white">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/gallery" className="text-white">
                  Gallery
                </a>
              </li>
              <li>
                <a href="/contact" className="text-white">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Us Section */}
          <div className="col-md-3 mb-3 mb-md-0">
            <h5 className="text-danger">Contact Us</h5>
            <ul className="list-unstyled">
              <li>
                <a href="tel:+9779802263936" className="text-white">
                  <FaPhoneAlt /> +977 9802263936
                </a>
              </li>
              <li>
                <a href="tel:+9779802263936" className="text-white">
                  <FaPhoneAlt /> +977 9802263936
                </a>
              </li>
              <li>
                <a href="mailto:info@bawarchinepal.com" className="text-white">
                  <FaEnvelope /> info@bawarchinepal.com
                </a>
              </li>
              <li>
                <span className="text-white">
                  <FaMapMarkerAlt /> Mid Baneshwor, Kathmandu, Nepal
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="text-center mt-4">
          <p className="mb-0">All Rights Reserved By Bawarchi Nepal @2024.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
