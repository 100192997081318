import React from "react";

function Account() {
  return (
    <div>
      <h1>Account</h1>
    </div>
  );
}

export default Account;
