import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./css/menu.css";

// Sample menu items
const menuItems = [
  {
    category: "Appetizers",
    items: [
      {
        id: 1,
        category: "Appetizers",
        name: "Sichuan Potatoes",
        price: 520,
        description: "Potatoes sautéed in garlic and sichuan peppers.",
      },
      {
        id: 2,
        category: "Appetizers",
        name: "Tangra Wontons Veg/Non-Veg",
        price: 660,
        description: "Bite-size dumplings made with veggies & cheese or meat.",
      },
      {
        id: 3,
        category: "Appetizers",
        name: "Spring Rolls Veg/Non-Veg",
        price: 690,
        description: "Thin sheet of dough filled with veggies or meat.",
      },
      {
        id: 4,
        category: "Appetizers",
        name: "Oyster Walnut and Paneer",
        price: 740,
        description: "Walnut and paneer sautéed with pan Asian condiments.",
      },
      {
        id: 5,
        category: "Appetizers",
        name: "Wild Wood Ear Mushroom in Chilli",
        price: 780,
        description: "Gelatinous textured mushroom cooked with Chinese spices.",
      },
      {
        id: 6,
        category: "Appetizers",
        name: "Tangra Chicken Wings",
        price: 690,
        description: "Chicken wings the Tangra Chinese way.",
      },
      {
        id: 7,
        category: "Appetizers",
        name: "Orange Chicken",
        price: 690,
        description: "Crispy chicken cooked in sweet and spicy orange sauce.",
      },
      {
        id: 8,
        category: "Appetizers",
        name: "Crispy Fried Fish",
        price: 790,
        description: "Fish fillets coated with golden batter and deep fried.",
      },
      {
        id: 9,
        category: "Appetizers",
        name: "Soy Garlic Prawn",
        price: 1180,
        description: "Prawn sautéed in ginger and tossed in secret soy sauce.",
      },
    ],
  },
  {
    category: "Main Course",
    items: [
      {
        id: 10,
        category: "Main Course",
        name: "Chinese Steamed or Fried Trout w/ Spicy Sauce",
        price: 2440,
        description: "Rainbow trout served steamed or fried.",
      },
      {
        id: 11,
        category: "Main Course",
        name: "Sweet & Sour Crispy Chicken",
        price: 890,
        description:
          "Authentic sweet and sour crispy chicken served with steamed rice.",
      },
      {
        id: 12,
        category: "Main Course",
        name: "Manchurian Veg/Non Veg",
        price: 890,
        description:
          "Deep fried veggie or meat balls cooked in sweet, spicy and tangy sauce.",
      },
      {
        id: 13,
        category: "Main Course",
        name: "Congee Lamb",
        price: 1090,
        description: "Shredded lamb fried and tossed in sweet and sour sauce.",
      },
      {
        id: 14,
        category: "Main Course",
        name: "Soy Ginger Prawn",
        price: 1180,
        description: "Prawns sautéed in ginger and soy sauce.",
      },
    ],
  },
  {
    category: "Choice of Momo",
    items: [
      {
        id: 15,
        category: "Choice of Momo",
        name: "Veg",
        price: 360,
        description: "",
      },
      {
        id: 16,
        category: "Choice of Momo",
        name: "Chicken",
        price: 420,
        description: "",
      },
      {
        id: 17,
        category: "Choice of Momo",
        name: "Mutton",
        price: 740,
        description: "",
      },
      {
        id: 18,
        category: "Choice of Momo",
        name: "Prawn",
        price: 1090,
        description: "",
      },
    ],
  },
  {
    category: "Choice of Chilli",
    items: [
      {
        id: 19,
        category: "Choice of Chilli",
        name: "Veg",
        price: 710,
        description: "",
      },
      {
        id: 20,
        category: "Choice of Chilli",
        name: "Chicken",
        price: 690,
        description: "",
      },
      {
        id: 21,
        category: "Choice of Chilli",
        name: "Mutton",
        price: 1080,
        description: "",
      },
      {
        id: 22,
        category: "Choice of Chilli",
        name: "Prawn",
        price: 1190,
        description: "",
      },
    ],
  },
  {
    category: "Choice of Tangra (Fried Rice & Hakka Noodles)",
    items: [
      {
        id: 23,
        category: "Choice of Tangra (Fried Rice & Hakka Noodles)",
        name: "Veg",
        price: 580,
        description: "",
      },
      {
        id: 24,
        category: "Choice of Tangra (Fried Rice & Hakka Noodles)",
        name: "Chicken",
        price: 680,
        description: "",
      },
      {
        id: 25,
        category: "Choice of Tangra (Fried Rice & Hakka Noodles)",
        name: "Mutton",
        price: 840,
        description: "",
      },
      {
        id: 26,
        category: "Choice of Tangra (Fried Rice & Hakka Noodles)",
        name: "Prawn",
        price: 1199,
        description: "",
      },
    ],
  },
  {
    category: "Dessert",
    items: [
      {
        id: 27,
        category: "Dessert",
        name: "Bawarchi Dessert",
        price: 799,
        description: "Sweet concoction of Bawarchi's finest.",
      },
      {
        id: 28,
        category: "Dessert",
        name: "Desserts from the Cellar",
        price: null,
        description: "Please refer to our server.",
      },
    ],
  },
  {
    category: "SAMUNDER KA KHAZANA",
    items: [
      {
        id: 29,
        category: "SAMUNDER KA KHAZANA",
        name: "Kali Mirch Methi Fish Tikka",
        price: 920,
        description:
          "Fish tikka with freshly crushed black pepper and dry fenugreek.",
      },
      {
        id: 30,
        category: "SAMUNDER KA KHAZANA",
        name: "Angara Amritsari Machhi",
        price: 990,
        description:
          "Coated with besan flour and special Bawarchi spices and deep fried.",
      },
      {
        id: 31,
        category: "SAMUNDER KA KHAZANA",
        name: "Tandoori Basil Prawn",
        price: 1260,
        description:
          "Juicy prawns marinated with basil and grilled to perfection.",
      },
      {
        id: 32,
        category: "SAMUNDER KA KHAZANA",
        name: "Prawn Tikka Chatpata",
        price: 1290,
        description:
          "Prawn tikka marinated in Indian spices and cooked in tandoor oven and tossed in tangy masala.",
      },
      {
        id: 33,
        category: "SAMUNDER KA KHAZANA",
        name: "Tandoori Platter Non-Veg",
        price: 2499,
        description: "Combination of five mouth-watering kebabs.",
      },
    ],
  },
  {
    category: "SHARARAT E SABZE",
    items: [
      {
        id: 34,
        category: "SHARARAT E SABZE",
        name: "Aloo Nazakat",
        price: 580,
        description: "Scooped potatoes stuffed with sweetcorn and nuts.",
      },
      {
        id: 35,
        category: "SHARARAT E SABZE",
        name: "Bhindi Kurkure",
        price: 590,
        description: "Crispy fried okra served sprinkled with chaat masala.",
      },
      {
        id: 36,
        category: "SHARARAT E SABZE",
        name: "Tandoori Gobi",
        price: 590,
        description: "Flavorful and healthy cauliflower baked in tandoor.",
      },
      {
        id: 37,
        category: "SHARARAT E SABZE",
        name: "Bawarchi Fries (Signature)",
        price: 599,
        description:
          "Finger cut potatoes doused in special batter and deep-fried.",
      },
      {
        id: 38,
        category: "SHARARAT E SABZE",
        name: "Seekh Kebab",
        price: 690,
        description: "Seekh done right... The Bawarchi way!",
      },
      {
        id: 39,
        category: "SHARARAT E SABZE",
        name: "Harabhara Kebab",
        price: 690,
        description:
          "Chana dal, spinach, green peas & paneer come together for this classic.",
      },
      {
        id: 40,
        category: "SHARARAT E SABZE",
        name: "Paneer Tikka Skewers",
        price: 740,
        description:
          "Cottage cheese marinated in pickle and cooked in tandoor oven.",
      },
      {
        id: 41,
        category: "SHARARAT E SABZE",
        name: "Katar Kebab",
        price: 690,
        description:
          "Marinated small pieces of jackfruit in yoghurt and Indian spices, cooked in tandoor.",
      },
      {
        id: 42,
        category: "SHARARAT E SABZE",
        name: "Cheesy Malai Paneer Tikka",
        price: 760,
        description: "Soft melt-in-the-mouth creamy paneer recipe with cheese.",
      },
      {
        id: 43,
        category: "SHARARAT E SABZE",
        name: "Soya Chaap",
        price: 690,
        description:
          "Grilled soya chunks, marinated with yoghurt, chaat masala, and butter.",
      },
      {
        id: 44,
        category: "SHARARAT E SABZE",
        name: "Cream Kari Corn Tikki",
        price: 720,
        description: "Corn with cashew nuts and the flavours alive.",
      },
      {
        id: 45,
        category: "SHARARAT E SABZE",
        name: "Shabnam K Moti",
        price: 690,
        description:
          "Mushroom caps filled with cream cheese and a subtle touch of mascarpone cheese, cooked to a golden hue in tandoor.",
      },
      {
        id: 46,
        category: "SHARARAT E SABZE",
        name: "Tandoori Platter Veg",
        price: 2299,
        description: "Combination of five mouth-watering kebabs.",
      },
    ],
  },
  {
    category: "LIZZAT E BIRYANI",
    items: [
      {
        id: 67,
        category: "LIZZAT E BIRYANI",
        name: "Sada Chawal",
        price: 320,
        description: "Fragrant basmati rice.",
      },
      {
        id: 68,
        category: "LIZZAT E BIRYANI",
        name: "Jeera Pulao",
        price: 390,
        description: "Slow-cooked steamed rice with jeera and homemade ghee.",
      },
      {
        id: 69,
        category: "LIZZAT E BIRYANI",
        name: "Matar Pulao",
        price: 390,
        description:
          "Slow-cooked steamed rice with green peas and homemade ghee.",
      },
      {
        id: 70,
        category: "LIZZAT E BIRYANI",
        name: "Katar Biryani",
        price: 790,
        description:
          "Spice mix of slow-cooked rice with jackfruit accompanied with raita.",
      },
      {
        id: 71,
        category: "LIZZAT E BIRYANI",
        name: "Nizam Tarkari Biryani",
        price: 820,
        description:
          "Spice mix of slow-cooked rice with veggies accompanied with raita.",
      },
      {
        id: 72,
        category: "LIZZAT E BIRYANI",
        name: "Dum Biryani Chicken",
        price: 1040,
        description:
          "Saffron flavored curry cooked with long grain rice in a sealed dish.",
      },
      {
        id: 73,
        category: "LIZZAT E BIRYANI",
        name: "Dum Biryani Mutton",
        price: 1280,
        description:
          "Saffron flavored curry cooked with long grain rice in a sealed dish.",
      },
      {
        id: 74,
        category: "LIZZAT E BIRYANI",
        name: "Dum Biryani Prawn",
        price: 1490,
        description:
          "Saffron flavored curry cooked with long grain rice in a sealed dish.",
      },
      {
        id: 75,
        category: "LIZZAT E BIRYANI",
        name: "Hyderabadi Biryani Chicken",
        price: 1180,
        description:
          "Aromatic Indian curry with long grain basmati rice in a sealed dish.",
      },
      {
        id: 76,
        category: "LIZZAT E BIRYANI",
        name: "Hyderabadi Biryani Mutton",
        price: 1340,
        description:
          "Aromatic Indian curry with long grain basmati rice in a sealed dish.",
      },
    ],
  },
  {
    category: "MURGH KE PAKWAN",
    items: [
      {
        id: 47,
        category: "MURGH KE PAKWAN",
        name: "Khurcha Saag Murgh",
        price: 840,
        description:
          "Shredded chicken tikkas tossed with spinach, fenugreek, colored peppers and peppercorns, tempered with mustard seeds.",
      },
      {
        id: 48,
        category: "MURGH KE PAKWAN",
        name: "Kadhai Butter Chicken",
        price: 840,
        description:
          "Pakistani dish noted for its spicy taste and use of capsicum.",
      },
      {
        id: 49,
        category: "MURGH KE PAKWAN",
        name: "Tariwala Murgh",
        price: 840,
        description:
          "Chicken gravy-from a dhaba on the famous Delhi ambala road.",
      },
      {
        id: 50,
        category: "MURGH KE PAKWAN",
        name: "Chicken Tikka Masala",
        price: 860,
        description: "Tandoor oven baked tikkas mixed in tangy masala sauce.",
      },
      {
        id: 51,
        category: "MURGH KE PAKWAN",
        name: "Butter Chicken",
        price: 890,
        description: "Delhi's finest dish. Served true to the core.",
      },
      {
        id: 52,
        category: "MURGH KE PAKWAN",
        name: "Chicken Mughlai",
        price: 1020,
        description:
          "From the imperial mughal courts... combination of chicken cubes in thick yellow gravy.",
      },
    ],
  },
  {
    category: "NAAN & ROTI",
    items: [
      {
        id: 53,
        category: "NAAN & ROTI",
        name: "Atta Tandoori Roti",
        price: 90,
        description: "Homemade flatbread made with wheat flour.",
      },
      {
        id: 54,
        category: "NAAN & ROTI",
        name: "Pyaz Mirchi Ki Roti",
        price: 140,
        description: "Traditional roti with a twist of Indian spices.",
      },
      {
        id: 55,
        category: "NAAN & ROTI",
        name: "Missi Roti",
        price: 180,
        description: "Traditional roti with a twist of Indian spices.",
      },
      {
        id: 56,
        category: "NAAN & ROTI",
        name: "Roomali Roti",
        price: 220,
        description: "Flatbread with savory and nutty taste.",
      },
      {
        id: 57,
        category: "NAAN & ROTI",
        name: "Naan",
        price: 140,
        description: "Authentic North Indian naan.",
      },
      {
        id: 58,
        category: "NAAN & ROTI",
        name: "Butter Naan",
        price: 190,
        description: "Naan doused in butter.",
      },
      {
        id: 59,
        category: "NAAN & ROTI",
        name: "Garlic Naan",
        price: 220,
        description: "Naan with the goodness of butter and garlic.",
      },
      {
        id: 60,
        category: "NAAN & ROTI",
        name: "Peshawari Naan",
        price: 220,
        description: "Naan for the Royals.",
      },
      {
        id: 61,
        category: "NAAN & ROTI",
        name: "Cheese Naan",
        price: 280,
        description: "Naan with the umami flavor of cheese.",
      },
      {
        id: 62,
        category: "NAAN & ROTI",
        name: "Lachha Paratha",
        price: 180,
        description: "Traditional South Indian paratha.",
      },
      {
        id: 63,
        category: "NAAN & ROTI",
        name: "Mint Paratha",
        price: 180,
        description:
          "Crispy and flaky thin layered flatbread topped with mint.",
      },
      {
        id: 64,
        category: "NAAN & ROTI",
        name: "Potato Stuffed Kulcha",
        price: 240,
        description: "Flatbread stuffed with potato and Bawarchi spices.",
      },
      {
        id: 65,
        category: "NAAN & ROTI",
        name: "Chicken Mince Stuffed Kulcha",
        price: 290,
        description:
          "Flatbread stuffed with chicken mince and Bawarchi spices.",
      },
      {
        id: 66,
        category: "NAAN & ROTI",
        name: "Lamb Mince Stuffed Kulcha",
        price: 290,
        description: "Flatbread stuffed with lamb mince and Bawarchi spices.",
      },
    ],
  },

  // Continue for the remaining categories in a similar fashion...
];

function Menu({ addToCart }) {
  window.onload = function () {
    const navbarHeight = document.querySelector(".navbar").offsetHeight;
    document.body.style.paddingTop = `${navbarHeight}px`;
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { deliveryType, address } = location.state || {}; // Get deliveryType and address
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Filtered items based on the selected category
  const filteredItems =
    selectedCategory === "All"
      ? menuItems.flatMap((category) => category.items)
      : menuItems.find((category) => category.category === selectedCategory)
          ?.items || [];

  const goToCart = () => {
    navigate("/cart", {
      state: { deliveryType, address }, // Pass deliveryType and address to Cart
    });
  };

  // Handle Category Selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  return (
    // <div className="mt-4 menu-container d-flex justify-content-center align-items-center">
    //   <div className="header">
    //     <h2 className="mb-0">Food Menu</h2>
    //     <p className="mb-0">
    //       <strong>Delivery Type:</strong> {deliveryType}
    //     </p>
    //     <p className="mb-0">
    //       <strong>Address:</strong> {address}
    //     </p>
    //   </div>

    //   {/* Category Buttons
    //   <div className="category-buttons">
    //     <button
    //       className={`btn ${
    //         selectedCategory === "All" ? "btn-primary" : "btn-outline-primary"
    //       }`}
    //       onClick={() => setSelectedCategory("All")}
    //     >
    //       Show All
    //     </button>
    //     {menuItems.map((category) => (
    //       <button
    //         key={category.category}
    //         className={`btn ${
    //           selectedCategory === category.category
    //             ? "btn-primary"
    //             : "btn-outline-primary"
    //         }`}
    //         onClick={() => setSelectedCategory(category.category)}
    //       >
    //         {category.category}
    //       </button>
    //     ))}
    //   </div> */}
    //   {/* Category Buttons */}
    //   <div className="category-buttons">
    //     <button
    //       className={`btn ${
    //         selectedCategory === "All" ? "btn-warning" : "btn-outline-warning"
    //       }`}
    //       onClick={() => setSelectedCategory("All")}
    //     >
    //       Show All
    //     </button>
    //     {menuItems.map((category) => (
    //       <button
    //         key={category.category}
    //         className={`btn ${
    //           selectedCategory === category.category
    //             ? "btn-warning"
    //             : "btn-outline-warning"
    //         }`}
    //         onClick={() => handleCategorySelect(category.category)}
    //       >
    //         {category.category}
    //       </button>
    //     ))}
    //   </div>

    //   {/* Render Menu Items */}
    //   <div className="row menu-items">
    //     {filteredItems.map((item) => (
    //       <div key={item.id} className="col-md-4 mb-4">
    //         <div className="card">
    //           <div className="card-body">
    //             <h5 className="card-title">{item.name}</h5>
    //             <p className="card-text">Rs {item.price}</p>
    //             <button
    //               className="btn btn-warning"
    //               onClick={() => addToCart(item)} // Add item to cart
    //             >
    //               Add to Cart
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>

    //   {/* Go to Cart Button */}
    //   <div className="go-to-cart-button text-center mt-4 mb-10">
    //     <button className="btn btn-success" onClick={goToCart}>
    //       Go to Cart
    //     </button>
    //   </div>
    // </div>

    <div className="mt-4 mb-5 menu-container ">
      <div className="header ">
        <h2>Food Menu</h2>
        <p>
          <strong>Delivery Type:</strong> {deliveryType}
        </p>
        <p>
          <strong>Address:</strong> {address}
        </p>
      </div>

      {/* Category Buttons */}
      <div className="category-buttons d-flex overflow-auto">
        <button
          className={`btn ${
            selectedCategory === "All" ? "btn-warning" : "btn-outline-warning"
          }`}
          onClick={() => handleCategorySelect("All")}
        >
          Show All
        </button>
        {menuItems.map((category) => (
          <button
            key={category.category}
            className={`btn ${
              selectedCategory === category.category
                ? "btn-warning"
                : "btn-outline-warning"
            }`}
            onClick={() => handleCategorySelect(category.category)}
          >
            {category.category}
          </button>
        ))}
      </div>

      {/* Render Menu Items */}
      <div className="row menu-items">
        {filteredItems.map((item) => (
          <div key={item.id} className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">{item.name}</h5>
                <p> {item.description}</p>
                <p className="card-text" style={{ color: "green" }}>
                  Rs {item.price}
                </p>
                <button
                  className="btn btn-warning"
                  onClick={() => addToCart(item)} // Add item to cart
                >
                  Add to Cart
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Go to Cart Button */}
      <div className="go-to-cart-button text-center mt-4 mb-10">
        <button className="btn btn-success" onClick={goToCart}>
          Go to Cart
        </button>
      </div>
    </div>
  );
}

export default Menu;
